.StudentTable {
  flex: 4;
  border-top: 1px solid rgb(98, 98, 98);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

a {
  text-decoration: none;
  color: white;
}
a:hover {
  color: black;
}

div.scroll {
}

.toastBody {
  width: 500px;
}
