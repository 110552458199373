.title h1 {
  color: #0b6ab0;
  font-weight: 700;
}

.button {
  color: white;
}

.logostyle {
  width: "20%";
  height: "20%";
}
.register {
  /* background-color: #5978e5; */
  background-color: #eaedf5;
}

.button:hover {
  color: #fff;
}

/* .eye {
  position: absolute;
} */

#hide1,
#hide3 {
  display: none;
}
:root {
  scroll-behavior: smooth;
}
/* body {
  font-family: "Open Sans", sans-serif;
  background-color: #5978e5;
  color: #444;
} */
.resetPassword {
  color: #0b6ab0;
  text-decoration: none;
}
a:hover {
  color: #0b6ab0;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Nunito, sans-serif;
}
@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 30px rgba(1, 41, 112, 0.1);
}
.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: Poppins, sans-serif;
}
.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}
.card-body {
  padding: 0 20px 20px 20px;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#dropdown-basic-button {
  background-color: aliceblue;
  color: rgb(99, 94, 94);

  border-color: #ced4da;
}
