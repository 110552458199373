.topbar {
  width: 100%;
  height: 70px;
  background-color: #f8f8f8;
  position: sticky;
  top: 0;

  z-index: 999;
}

.img {
  padding: 0;
  margin-left: 50%;
  max-height: 60px;
  height: 70px;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  font-weight: bold;
  font-size: 30px;
  color: #5978e5;
  cursor: pointer;
}
.user {
  font-weight: bold;
  font-size: 18px;
  color: #5978e5;
  margin-right: 10px;
  cursor: pointer;
}

.topRight {
  display: flex;
  align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}

.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.logout {
  margin-left: 10px;
  border: none;
}
