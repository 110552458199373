.table-header {
  background-color: #bacde5;
}

.header-cell {
  font-weight: 700 !important;
}

.approval-table {
  flex: 4 1;
}
