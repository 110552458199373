/* ::::::::::::::::::::::::::::: Analytics */
.section-header {
  margin: 20px 20px;
}

.analytics {
  margin: 2rem 0;
}
.analytics .list-analytics {
  display: flex;
  justify-content: space-between;
  margin: auto 20px;
}
.analytics .list-analytics .analytic {
  background-color: rgb(240, 238, 247);
  width: 23%;
  border-radius: 10px;
  text-align: center;
  padding: 2rem 1rem;
  transition: box-shadow 0.2s ease-in;
}
.analytics .list-analytics .analytic:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.analytics .list-analytics .analytic .icon {
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
}

.analytics .list-analytics .a .icon {
  background-color: #e5f3ff;
  color: #0277ed;
}
.analytics .list-analytics .b .icon {
  background-color: #f4f1ff;
  color: #8964f9;
}
.analytics .list-analytics .c .icon {
  background-color: #fff9e5;
  color: #f4b20a;
}
.analytics .list-analytics .d .icon {
  background-color: #ebfaea;
  color: #46bd4c;
}

.analytics .list-analytics .analytic .num {
  font-size: 40px;
  margin: 25px 0 5px 0;
  transform: scaleY(1.1);
}
.analytics .list-analytics .analytic .desc {
  font-size: 12px;
  color: #7f8488;
  margin-bottom: 35px;
}

.analytics .list-analytics .analytic .up {
  color: #1ebe2c;
}
.analytics .list-analytics .analytic .down {
  color: #f62421;
}

.analytics .list-analytics .analytic .percent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.analytics .list-analytics .analytic .percent .value {
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}
