.sidebar {
  flex: 1;
  height: calc(100vh - 50px);
  background-color: #5978e5;
  position: sticky;
  top: 50px;
  min-width: 16%;
}

.sidebarTitle a:hover {
  color: #fff;
}

.sidebarWrapper {
  padding: 20px;
  color: rgb(255, 255, 255);
}

.sidebarMenu {
  margin-bottom: 15px;
}

.sidebarTitle {
  font-size: 15px;
  font-weight: 600;
  color: rgb(255, 255, 255);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  display: block;
}

.sidebarListItem a {
  display: block;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(4, 4, 4);
}

.sidebarListItem:hover a {
  color: rgb(4, 4, 4);
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

.add {
  color: #fff;
}
